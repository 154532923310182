/* latin-ext */
@font-face {
  font-family: '__Knewave_d14b8b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/2d5794ff0d357647-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Knewave_d14b8b';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/9d3afea8674f91ff-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Knewave_Fallback_d14b8b';src: local("Arial");ascent-override: 110.88%;descent-override: 36.52%;line-gap-override: 0.00%;size-adjust: 105.43%
}.__className_d14b8b {font-family: '__Knewave_d14b8b', '__Knewave_Fallback_d14b8b';font-weight: 400;font-style: normal
}.__variable_d14b8b {--font-heading: '__Knewave_d14b8b', '__Knewave_Fallback_d14b8b'
}

